import React from "react";

// Stylesheets
import "./Loader.scss";

const Loader = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="la-atom la-3x">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </section>
  );
};

export default Loader;
