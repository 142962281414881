import React from "react";

// Stylesheets
import "./Notification.scss";

const Notification = props => {
  return (
    <div className={`notification ${props.className}`}>{props.children}</div>
  );
};

export default Notification;
