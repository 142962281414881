import React from "react";

// Stylesheets
import "./Hero.scss";

const Hero = props => {
  return (
    <section className={`hero is-small ${props.className}`}>
      <div className="hero-body has-text-centered">
        <h1 className="title is-2">{props.title}</h1>
        <h4 className="subtitle">{props.subtitle}</h4>
        {props.children}
      </div>
    </section>
  );
};

export default Hero;
