import React from 'react';

// Stylesheets
import './Comment.scss';

const Comment = (props) => {
  let date = new Date(props.email.date);
  return (
    <div key={props.email.id} className="box">
      <article className="media">
        <div className="media-content">
          <div className="content">
            <p>
              <strong>{props.email.subject}</strong> <br />
              <small>
                <b>{props.email.sender_address}</b> to{' '}
                <b>{props.email.receiver}</b>
              </small>
              <br />
              <small>
                <small>
                  {props.email.cc !== '' ? `CC: ${props.email.cc}` : ''}
                </small>
              </small>
              <br />
              <small>{date.toLocaleString()}</small>
              <br />
            </p>
            <pre>{props.email.body}</pre>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Comment;
