import React from 'react';

// Dependencies
import { Redirect } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';

// Functions
import signup from '../../functions/signup';
import verifyCaptcha from '../../functions/verifyCaptcha';

// Components
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import Loader from '../../components/Loader/Loader';
import Notification from '../../components/Notification/Notification';

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showError: false,
      showForm: true,
      showButton: false,
      toDashboard: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.issueSignup = this.issueSignUp.bind(this);
  }

  onChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  onSubmit(event) {
    this.setState({ showLanding: false, showLoader: true });

    this.issueSignUp();

    event.preventDefault();
    event.stopPropagation();
  }

  async onVerify(captchaResponse) {
    let response = await verifyCaptcha(captchaResponse);

    if (response.status === 200) {
      this.setState({ showButton: true });
    } else {
      console.log(response);
      this.setState({
        showLoader: false,
        error:
          'Could not verify reCAPTCHA response. Please reload and try again.',
        showError: true,
      });
    }
  }

  async issueSignUp() {
    let response = await signup(
      this.state.first,
      this.state.last,
      this.state.email,
      this.state.password,
      this.state.confirmation
    );

    if (response.status === 201) {
      sessionStorage.setItem('satellite-csp-token', response.data.token);
      this.props.setAuth(true);
      this.setState({ showLoader: false, toDashboard: true });
    } else if (response.status === 400) {
      this.setState({
        showLoader: false,
        error: 'That user already exists.',
        showError: true,
      });
    } else if (response.status === 404) {
      this.setState({
        showLoader: false,
        error:
          'That email does not appear in our system. ' +
          'Are you sure you signed up with the right one?',
        showError: true,
      });
    } else {
      this.setState({
        showLoader: false,
        error: response.message,
        showError: true,
      });
    }
  }

  render() {
    if (this.state.showLoader) {
      return <Loader />;
    } else if (this.state.showError) {
      return (
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <Notification className="is-danger">
                  <p>{this.state.error}</p>
                  <p>
                    <small>
                      If the problem persists and is out of the ordinary, please
                      contact support@smartian.space.
                    </small>
                  </p>
                </Notification>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (this.state.toDashboard) {
      return <Redirect to="/dashboard" />;
    } else if (this.state.showForm) {
      return (
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <form className="form" onSubmit={this.onSubmit}>
                  <Card>
                    <div className="field">
                      <div className="columns">
                        <div className="column">
                          <div className="control">
                            <input
                              type="text"
                              name="first"
                              placeholder="First Name"
                              className="input is-medium"
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="column">
                          <div className="control">
                            <input
                              type="text"
                              name="last"
                              placeholder="Last Name"
                              className="input is-medium"
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="input is-medium"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="input is-medium"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <input
                          type="password"
                          name="confirmation"
                          placeholder="Confirm password"
                          className="input is-medium"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <br />
                    {process.env.NODE_ENV !== 'development' ? (
                      <>
                        <div className="columns is-centered">
                          <div className="column is-narrow">
                            <div className="field">
                              <div className="control">
                                <Recaptcha
                                  sitekey="6LeOffQUAAAAAOthFd_R-fFNjNh-jg4MQFfZ0rFg"
                                  verifyCallback={this.onVerify}
                                  onloadCallback={() => console.log('loaded')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                      </>
                    ) : (
                      ''
                    )}
                    <div className="field">
                      <div className="control has-text-centered">
                        {this.state.showButton ||
                        process.env.NODE_ENV === 'development' ? (
                          <Button
                            className="button is-standard is-caps"
                            text="CONTINUE"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Card>
                </form>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default SignUp;
