import axios from 'axios'

const signup = async (first, last, email, password, confirmation) => {
    const instance = axios.create({
        baseURL: window.location.href.includes('localhost')
            ? 'http://localhost:8000' : 'https://csp.satellites.smartian.space'
    })
    instance.defaults.validateStatus = status => status >= 200 && status < 500

    const data = {
        first,
        last,
        username: email,
        email,
        password,
        confirmation
    }

    const response = await instance
        .post('/signup', data)
        .then(res => res)
        .catch(err => err)

    return response
}

export default signup
