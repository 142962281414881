import React from 'react';

// Functions
import getDashboard from '../../functions/getDashboard';

// Components
import Hero from '../../components/Hero/Hero';
import Loader from '../../components/Loader/Loader';
import Notification from '../../components/Notification/Notification';
import { Link } from 'react-router-dom';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      showError: false,
      showDashboard: false,
      sortBy: 'number',
    };

    this.handleChange = this.handleChange.bind(this);
    this.issueGetDashboard = this.issueGetDashboard.bind(this);
  }

  componentDidMount() {
    this.issueGetDashboard();
  }

  handleChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;

    this.setState(newState);
  }

  async issueGetDashboard() {
    let response = await getDashboard();

    if (response.status === 200) {
      this.setState(response.data);
      this.setState({ showLoader: false, showDashboard: true });
    } else if (response.status === 403) {
      this.setState({
        showLoader: false,
        error: 'Please check your inbox to confirm your email address.',
        showError: true,
      });
    } else {
      this.setState({
        showLoader: false,
        error: response.message,
        showError: true,
      });
    }
  }

  render() {
    if (this.state.showLoader) {
      return <Loader />;
    } else if (this.state.showError) {
      return (
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <Notification className="is-danger">
                  <p>{this.state.error}</p>
                  <p>
                    <small>
                      If you run into any issues, please contact
                      support@smartian.space.
                    </small>
                  </p>
                </Notification>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (this.state.showDashboard) {
      let cases_sorted = this.state.cases;
      if (this.state.sortBy === 'number') {
        cases_sorted.sort((a, b) => {
          if (a.number < b.number) {
            return -1;
          } else if (a.number > b.number) {
            return 1;
          } else {
            return 0;
          }
        });
      } else if (this.state.sortBy === 'opened') {
        cases_sorted.sort((a, b) => {
          return new Date(b.opened) - new Date(a.opened);
        });
      } else if (this.state.sortBy === 'subject') {
        cases_sorted.sort((a, b) => {
          return a.subject.localeCompare(b.subject);
        });
      } else if (this.state.sortBy === 'type') {
        cases_sorted.sort((a, b) => {
          return b.ctype.localeCompare(a.ctype);
        });
      } else if (this.state.sortBy === 'priority') {
        cases_sorted.sort((a, b) => {
          if (a.priority < b.priority) {
            return -1;
          } else if (a.priority > b.priority) {
            return 1;
          } else {
            return 0;
          }
        });
      } else if (this.state.sortBy === 'type') {
        cases_sorted.sort((a, b) => {
          return a.status.localeCompare(b.status);
        });
      }

      let cases = [];
      for (const _case of cases_sorted) {
        cases.push(
          <tr key={_case.number}>
            <td>{_case.number}</td>
            <td>{this.state.contact.name}</td>
            <td>{new Date(_case.opened).toLocaleDateString()}</td>
            <td>{_case.subject}</td>
            <td className="is-narrow">{_case.ctype}</td>
            <td className="has-text-centered">{_case.priority}</td>
            <td className="has-text-centered">{_case.status}</td>
            <td className="has-text-centered">
              <Link to={`/case/${_case.id}`}>
                <i className="fas fa-external-link-alt"></i>
              </Link>
            </td>
          </tr>
        );
      }

      return (
        <div>
          <Hero title={`Cases for ${this.state.contact.name}`} />
          <section className="section">
            <div className="container">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <div className="field">
                      <label className="label">Filter by</label>
                      <div className="control">
                        <div className="select">
                          <select
                            name="sortBy"
                            id="sortBy"
                            onChange={this.handleChange}
                          >
                            <option value="number">Number</option>
                            <option value="opened">Date Opened</option>
                            <option value="subject">Subject</option>
                            <option value="type">Type</option>
                            <option value="priority">Priority</option>
                            <option value="status">Status</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="level-right">
                  <span className="tag is-light is-loading">
                    {this.state.contact.syncing
                      ? 'Sync in progress. Reload the page to update dashboard.'
                      : `Last sync: ${new Date(
                          this.state.contact.last_sync
                        ).toLocaleTimeString()}`}
                  </span>
                </div>
              </div>
              <br />
              <table className="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th className="is-narrow">Number</th>
                    <th className="is-narrow">Requester</th>
                    <th className="is-narrow">Date Opened</th>
                    <th>Subject</th>
                    <th className="is-narrow">Type</th>
                    <th className="is-narrow">Priority</th>
                    <th className="is-narrow">Status</th>
                    <th className="is-narrow">Details</th>
                  </tr>
                </thead>
                <tbody>{cases}</tbody>
              </table>
            </div>
          </section>
        </div>
      );
    }
  }
}

export default Dashboard;
