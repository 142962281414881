import React from 'react';
import { Redirect } from 'react-router-dom';

const Logout = (props) => {
  sessionStorage.removeItem('satellite-csp-token');
  props.setAuth(false);

  return <Redirect to="/" />;
};

export default Logout;
