import React from 'react';

// Components
import Hero from '../../components/Hero/Hero';

// Stylesheets
import './Landing.scss';

// Assets
import Eye from '../../assets/images/eye.png'
import Cloud from '../../assets/images/cloud.png'

const Landing = (props) => {
  return (
    <>
      <Hero title="Customer Support Portal" subtitle="Track your cases in real-time." />
      <section className="hero is-light">
        <div className="hero-body has-text-centered">
          <div className="columns is-centered">
            <div className="column is-5">
              <p>
                <img
                  src={Eye}
                  alt="Visibility"
                  style={{ height: '4rem', marginBottom: '.3rem' }}
                />
              </p>
              <h1 className="title is-4 is-blue">Thorough Visibility</h1>
              <div className="content">
                <p>
                  See all of your cases, open or otherwise, in one place, with
                  all the details you need to know exactly what's going on.
                  </p>
              </div>
            </div>
            <div className="vl"></div>
            <div className="column is-5">
              <p>
                <img
                  src={Cloud}
                  alt="Sync"
                  style={{ height: '4rem', marginBottom: '.3rem' }}
                />
              </p>
              <h1 className="title is-4 is-blue">Continual Synchronization</h1>
              <div className="content">
                <p>
                  This portal is always talking with our systems to keep all
                  crucial information present up-to-date with the latest data.
                  </p>
              </div>
            </div>
          </div>
          <br />
        </div>
      </section>
      <section className="section">
        <div className="columns is-centered">
          <div className="column is-4">
            <div className="content has-text-centered">
              <small>
                Have a quick question? Premium Support plans give you access
                to a Live Customer Support Agent. Contact your CSM for details.
              </small>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Landing;
