import axios from 'axios'

const getDashboard = async () => {
    const instance = axios.create({
        baseURL: window.location.href.includes('localhost')
            ? 'http://localhost:8000' : 'https://csp.satellites.smartian.space'
    })
    instance.defaults.validateStatus = status => status >= 200 && status < 500
    instance.defaults.headers.common.Authorization = `Token ${sessionStorage.getItem(
        'satellite-csp-token'
    )}`

    const response = await instance
        .get('/load-dashboard')
        .then(res => res)
        .catch(err => err)

    return response
}

export default getDashboard
