import React from 'react';

// Dependencies
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

// Stylesheets
import './Navbar.scss';

// Assets
import title from '../../assets/images/title-white.png';

const Navbar = (props) => {
  return (
    <div className="Navbar">
      <nav className="navbar">
        <Link className="navbar-brand" to="/">
          <img className="navbar-logo" src={title} alt="SmartRecruiters" />
        </Link>
        <div className="navbar-menu">
          <div className="navbar-end">
            {props.auth || sessionStorage.getItem('satellite-csp-token') ? (
              <>
                <Link className="navbar-item is-caps" to="/dashboard">
                  DASHBOARD
                </Link>
                <Link className="navbar-item is-caps" to="/logout">
                  LOGOUT
                </Link>
              </>
            ) : (
                <>
                  <Link className="navbar-item is-caps" to="/login" data-tip="Sign up required! This system is not connected to SmartRecruit.">
                    LOG IN
                  </Link>
                  <ReactTooltip />
                  <Link className="navbar-item is-caps" to="/signup">
                    SIGN UP
                </Link>
                </>
              )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
