import axios from 'axios'

const verifyCaptcha = async captchaResponse => {
    const response = await axios
        .post(
            '/verify-captcha',
            {captchaResponse})
        .then(res => res)
        .catch(err => err)

    return response
}

export default verifyCaptcha
