import React, { useState } from 'react';

// Dependencies
import { Switch, Route } from 'react-router-dom';

// Views
import Landing from './views/Landing/Landing';
import Dashboard from './views/Dashboard/Dashboard';
import Case from './views/Case/Case';

// Components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import SignUp from './views/SignUp/SignUp';
import Login from './views/Login/Login';
import Logout from './views/Logout/Logout';

const App = () => {
  const [auth, setAuth] = useState(false);

  return (
    <>
      <Navbar auth={auth} />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route
          path="/signup"
          render={(props) => <SignUp {...props} setAuth={setAuth} />}
        />
        <Route
          path="/login"
          render={(props) => <Login {...props} setAuth={setAuth} />}
        />
        <Route
          path="/logout"
          render={(props) => <Logout {...props} setAuth={setAuth} />}
        />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/case/:caseId" component={Case} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
