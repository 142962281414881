import React from 'react';

// Functions
import getCase from '../../functions/getCase';
import addComment from '../../functions/addComment';

// Components
import Card from '../../components/Card/Card';
import Loader from '../../components/Loader/Loader';
import Notification from '../../components/Notification/Notification';
import Button from '../../components/Button/Button';
import Comment from '../../components/Comment/Comment';

class Case extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoader: true,
      showError: false,
      showCase: false,
      showCommentForm: false,
      showButtonLoader: false,
    };

    this.toggleCommentForm = this.toggleCommentForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddComment = this.handleAddComment.bind(this);
    this.issueGetCase = this.issueGetCase.bind(this);
    this.issueAddComment = this.issueAddComment.bind(this);
  }

  componentDidMount() {
    this.issueGetCase();
  }

  toggleCommentForm(event) {
    if (this.state.showCommentForm) {
      this.setState({ showCommentForm: false });
    } else {
      this.setState({ showCommentForm: true });
    }
  }

  handleChange(event) {
    let newState = {};
    newState[event.target.name] = event.target.value;

    this.setState(newState);
  }

  handleAddComment(event) {
    this.setState({ showButtonLoader: true });

    this.issueAddComment();

    event.preventDefault();
    event.stopPropagation();
  }

  async issueGetCase() {
    let response = await getCase(this.props.match.params.caseId);

    if (response.status === 200) {
      this.setState(response.data);
      this.setState({
        showLoader: false,
        showButtonLoader: false,
        showCase: true,
      });
    } else {
      this.setState({
        showLoader: false,
        error: response.message,
        showError: true,
      });
    }
  }

  async issueAddComment() {
    let emails = this.state.emails;
    emails.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    let data = {
      sender_name: this.state.contact.name,
      sender_address: this.state.contact.email,
      receiver: 'support@smartrecruiters.com',
      cc: typeof emails !== 'undefined' && emails.length > 0 ? emails[0].cc : '',
      date: new Date().toISOString(),
      subject: `Reply to Case: ${this.state.case.number} - ${this.state.case.subject}`,
      body: this.state.body,
    };

    let response = await addComment(this.props.match.params.caseId, data);

    if (response.status === 201) {
      this.setState({ showCommentForm: false });
      this.issueGetCase();
    } else {
      this.setState({
        showLoader: false,
        error: response.message,
        showError: true,
      });
    }
  }

  render() {
    if (this.state.showLoader) {
      return <Loader />;
    } else if (this.state.showError) {
      return (
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-half">
                <Notification className="is-danger">
                  <p>
                    Oops - something didn't work! You can reload the page and
                    try again, or if the problem persists, please contact
                    support@smartian.space.
                  </p>
                  <p>
                    <small>{this.state.error}</small>
                  </p>
                </Notification>
              </div>
            </div>
          </div>
        </section>
      );
    } else if (this.state.showCase) {
      let sorted_emails = this.state.emails;
      sorted_emails.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });

      let emails = [];
      for (const email of sorted_emails) {
        emails.push(<Comment email={email} />);
      }

      return (
        <section className="Case section">
          <div className="container">
            <div className="columns">
              <div className="column is-one-third">
                <Card className="meta-card">
                  <h1 className="title is-4">Case Details</h1>
                  <h2 className="subtitle is-5">{this.state.case.number}</h2>
                  <div className="content">
                    <p>
                      Type: <b>{this.state.case.ctype}</b>
                    </p>
                    <p>
                      Priority: <b>{this.state.case.priority}</b>
                    </p>
                    <p>
                      Status: <b>{this.state.case.status}</b>
                    </p>
                    <br />
                    <p>
                      Requester: <b>{this.state.contact.name}</b>
                    </p>
                    <p>
                      Date Opened:{' '}
                      <b>
                        {new Date(this.state.case.opened).toLocaleDateString()}
                      </b>
                    </p>
                    <p>
                      Subject: <b>{this.state.case.subject}</b>
                    </p>
                    <p>
                      Description: <b>{this.state.case.description}</b>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="column is-two-thirds">
                <div className="level">
                  <div className="level-left"></div>
                  <div className="level-right">
                    <div className="level-item">
                      <span className="tag is-light is-loading">
                        {this.state.case.syncing
                          ? 'Sync in progress. Reload the page to update page.'
                          : `Last sync: ${new Date(
                            this.state.contact.last_sync
                          ).toLocaleTimeString()}`}
                      </span>
                    </div>
                  </div>
                </div>
                {this.state.showCommentForm ? (
                  <div className="box">
                    <div className="field">
                      <div className="control">
                        <textarea
                          name="body"
                          id="body"
                          className="textarea"
                          onChange={this.handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <br />
                    <div className="field">
                      <div className="control">
                        <div className="level">
                          <div className="level-left">
                            <div className="level-item">
                              <Button
                                className={`button is-caps ${this.state.showButtonLoader
                                  ? 'is-loading'
                                  : ''
                                  }`}
                                text="ADD COMMENT"
                                onClick={this.handleAddComment}
                              />
                            </div>
                          </div>
                          <div className="level-right">
                            <div className="level-item">
                              <Button
                                className="button is-caps is-outlined"
                                text="HIDE"
                                onClick={this.toggleCommentForm}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                    <Button
                      className="button is-caps is-fullwidth is-outlined"
                      text="ADD COMMENT"
                      onClick={this.toggleCommentForm}
                    />
                  )}

                <br />
                {emails}
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default Case;
