import React from "react";

// Stylesheets
import "./Card.scss";

const Card = props => {
  return (
    <div className={`card ${props.className}`}>
      {props.title ? (
        <div className="card-header">
          <h1 className="card-header-title">{props.title}</h1>
        </div>
      ) : (
        ""
      )}
      <div className="card-content">{props.children}</div>
    </div>
  );
};

export default Card;
